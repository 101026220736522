import React from 'react';
import Link from 'next/link';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { Field, reduxForm } from 'redux-form';
import { InputField, required } from '@components/field/Field';
import PasswordField from '@components/field/PasswordField';
import { loginUser } from '@actions/auth.action';
import Button from 'reactstrap-button-loader';
import { UncontrolledAlert } from 'reactstrap';
import { withTranslation } from '@helpers/i18n';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = value => {
    const { loginUser } = this.props;
    const form = {
      email: value.email,
      password: value.password
    };
    loginUser(form);
  };

  render() {
    const { handleSubmit, data, t } = this.props;

    const getLoginError = () => {
      switch (data.errorMessage) {
        case 'inactive_user':
          return 'Sorry , please wait until your card active to login';
        case 'invalid_credentials':
          return 'Please check your email or password';
        case 'no_permission':
          return "Sorry, you don't have access to this site, please contact your administrator";
      }
      return '';
    };

    return (
      <div>
        <div className='container full-container'>
          <div className='row justify-content-center pb-4'>
            <div className='col-12 text-center'>
              <Link href='/'>
                <span className='h1 font-weight-bold text-primary'>Apollo</span>
              </Link>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-11 col-lg-5'>
              <div className='card px-md-4 rounded'>
                <div className='card-header pt-4 text-center bg-white border-0'>
                  <span className='h4'>{t('Sign In to your account')}</span>
                </div>
                {getLoginError() !== '' && (
                  <UncontrolledAlert
                    color='danger'
                    className='m-3'
                    style={{
                      color: '#fff',
                      background: '#d64d37',
                      borderRadius: '5px'
                    }}
                  >
                    <p style={{ margin: '0.05rem 0' }}>{getLoginError()}</p>
                  </UncontrolledAlert>
                )}

                <div className='card-body pb-5'>
                  <form onSubmit={handleSubmit(this.onSubmit)}>
                    <div className='pb-3'>
                      <Field
                        name='email'
                        id='email'
                        type='text'
                        component={InputField}
                        placeholder={t('Input email')}
                        autoComplete='username'
                        label='Email'
                        validate={[required]}
                      />
                    </div>
                    <div className='pb-3'>
                      <Field
                        name='password'
                        id='password'
                        type='password'
                        component={PasswordField}
                        placeholder='Input password'
                        label='Password'
                        validate={[required]}
                      />
                    </div>
                    <div className='d-md-flex align-items-center justify-content-between'>
                      <div className='text-md-left text-center mb-md-0 mb-3'>
                        <a
                          href='/forgot-password'
                          className='font-weight-semibold'
                        >
                          {t('Forgot password?')}
                        </a>
                      </div>
                      <div className='text-md-left text-center'>
                        <Button
                          type='submit'
                          className='btn btn-primary px-4 py-2 w-md-auto w-100'
                          loading={data.isLoading}
                        >
                          {t('Login')}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row py-4">
            <div className="col-12 text-center">
              <span>
                Don't have any account yet? <a href="#">Create Account</a>
              </span>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.auth
});
export default withTranslation(['auth', 'common'])(
  withRouter(
    reduxForm({
      form: 'Login'
    })(connect(mapStateToProps, { loginUser })(Login))
  )
);
