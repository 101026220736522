import React, { Component } from 'react';

class PasswordField extends Component {
  state = {
    type: 'password',
    isShow: false
  };

  handleClick = () => {
    const { type } = this.state;

    if (type === 'password') {
      this.setState({ type: 'text' });
    } else {
      this.setState({ type: 'password' });
    }
  };

  render() {
    const { type, isShow } = this.state;
    const {
      input,
      placeholder,
      disabled,
      label,
      noLabel,
      requiredStar,
      id,
      meta: { touched, error, warning }
    } = this.props;

    return (
      <div className='form-group mb-0'>
        {!noLabel && (
          <label htmlFor={id}>
            {label}
            {requiredStar === true ? (
              <span className='text-required'> *</span>
            ) : (
              ''
            )}
          </label>
        )}
        <div
          className={`border p-1 px-2 rounded-sm row no-gutters ${touched &&
            error &&
            'border-danger'}`}
        >
          <div className='col-10 m-0'>
            <input
              {...input}
              placeholder={placeholder}
              type={type}
              disabled={disabled}
              id={id}
              autoComplete='current-password'
              className='border-0 outline-0 w-100'
            />
          </div>
          <div className='col-2 m-0'>
            <button
              type='button'
              className='btn p-0 m-0 col'
              onClick={this.handleClick.bind(this)}
            >
              {type === 'password' ? (
                <i className='fas fa-eye-slash pl-2' />
              ) : (
                <i className='fas fa-eye pl-2' />
              )}
            </button>
          </div>
        </div>
        {touched &&
          ((error && (
            <span className='form-error text-danger text-12'>{error}</span>
          )) ||
            (warning && <span className='form-error'>{warning}</span>))}
      </div>
    );
  }
}

export default PasswordField;
