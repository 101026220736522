import React from 'react';
import Login from '@containers/auth/Login';
import HeadDefault from '@layout/head/HeadDefault';

const LoginPage = () => (
  <div>
    <HeadDefault title='Apollo | Login' description='' />
    <Login />
  </div>
);

export default LoginPage;
